
.temp {
  display: inline-block;
  font-size: 15vh;
  position: relative;
  line-height: 15vh;
}
.tempUnit {
  position: absolute;
  top: 4vh;
  font-size: 2vh;
}