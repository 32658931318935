html {
  transition: all .2s;
  background: linear-gradient(135deg, #197fd6, #6da6e4);
}

::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-track {
  border-radius: 3px;
  background: rgba(0, 0, 0, 0.06);
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.08);
}
/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background: rgba(0, 0, 0, 0.12);
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);
}

body {
  
}
#root {
  height: 100%;
}

.App {
  text-align: center;
  color: white;
  height: 100%;
}
.ant-spin-dot-item {
  background-color: #fff!important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.block {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
}
.words {
  width: 100%!important;
  position: relative;
  width: fit-content;
  animation:move 20s linear infinite;
                    padding-left:50%;
}
.words::after{
  position: absolute; 
  right:-100%;
  content:attr(text);
}

@keyframes move {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}